<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n code="entities.staatsbuergerschaft.menu"></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
      <h1 class="app-content-title">
        <app-i18n code="entities.staatsbuergerschaft.list.title"></app-i18n>
      </h1>

      <app-staatsbuergerschaft-list-toolbar></app-staatsbuergerschaft-list-toolbar>
      <app-staatsbuergerschaft-list-filter></app-staatsbuergerschaft-list-filter>
      <app-staatsbuergerschaft-list-table></app-staatsbuergerschaft-list-table>
    </div>
  </div>
</template>

<script>
import StaatsbuergerschaftListFilter from '@/modules/staatsbuergerschaft/components/staatsbuergerschaft-list-filter.vue';
import StaatsbuergerschaftListTable from '@/modules/staatsbuergerschaft/components/staatsbuergerschaft-list-table.vue';
import StaatsbuergerschaftListToolbar from '@/modules/staatsbuergerschaft/components/staatsbuergerschaft-list-toolbar.vue';

export default {
  name: 'app-staatsbuergerschaft-list-page',

  components: {
    'app-staatsbuergerschaft-list-filter': StaatsbuergerschaftListFilter,
    'app-staatsbuergerschaft-list-table': StaatsbuergerschaftListTable,
    'app-staatsbuergerschaft-list-toolbar': StaatsbuergerschaftListToolbar,
  },
};
</script>

<style></style>
