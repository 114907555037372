<template>
  <el-form
    :label-position="labelPosition"
    :label-width="labelWidthFilter"
    :model="model"
    :rules="rules"
    @submit.native.prevent="doFilter"
    class="filter"
    ref="form"
  >
    <el-row type="flex">
      <el-col :lg="6" :md="12" :sm="16">
        <el-form-item
          :label="fields.langtext.label"
          :prop="fields.langtext.name"
        >
          <el-input v-model="model[fields.langtext.name]" ref="focus" />
        </el-form-item>
      </el-col>
      <el-col :lg="6" :md="12" :sm="16">
        <el-form-item
          :label="fields.nationscode.label"
          :prop="fields.nationscode.name"
        >
          <el-input v-model="model[fields.nationscode.name]" ref="focus" />
        </el-form-item>
      </el-col>
    </el-row>

    <div class="filter-buttons">
      <el-button
        :disabled="loading"
        @click="doFilter"
        icon="el-icon-fa-search"
        type="primary"
      >
        <app-i18n code="common.search"></app-i18n>
      </el-button>

      <el-button
        :disabled="loading"
        @click="doResetFilter"
        icon="el-icon-fa-undo"
      >
        <app-i18n code="common.reset"></app-i18n>
      </el-button>
    </div>
  </el-form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { FilterSchema } from '@/shared/form/filter-schema';
import { StaatsbuergerschaftModel } from '@/modules/staatsbuergerschaft/staatsbuergerschaft-model';

const { fields } = StaatsbuergerschaftModel;

const filterSchema = new FilterSchema([fields.langtext, fields.nationscode]);

export default {
  name: 'app-staatsbuergerschaft-list-filter',

  data() {
    return {
      rules: filterSchema.rules(),
      model: {},
    };
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthFilter: 'layout/labelWidthFilter',
      loading: 'staatsbuergerschaft/list/loading',
      filter: 'staatsbuergerschaft/list/filter',
    }),

    fields() {
      return fields;
    },
  },

  async mounted() {
    this.model = filterSchema.initialValues(this.filter, this.$route.query);

    return this.doFilter();
  },

  methods: {
    ...mapActions({
      doReset: 'staatsbuergerschaft/list/doReset',
      doFetch: 'staatsbuergerschaft/list/doFetch',
    }),

    async doResetFilter() {
      this.model = filterSchema.initialValues();
      this.$refs.form.clearValidate();
      return this.doReset();
    },

    async doFilter() {
      try {
        await this.$refs.form.validate();
        this.$refs.form.clearValidate();
      } catch (error) {
        return;
      }

      const filter = filterSchema.cast(this.model);
      return this.doFetch({
        filter,
      });
    },
  },
};
</script>

<style></style>
